import axios from "axios"
import {map} from "lodash";
import SurveySwitcherCheckView from "views/SurveySwitcherCheckView";
import SurveyResponseFakers from "./scenes/Survey/SurveyResponseFakers";

export default {
  config: {
    get: () => axios.get('/api/config').then(res => res)
  },
  cint: {
    list: () => axios.get('/api/cint/list').then(res => res.data),
    listCsv: `/api/cint/list-csv?auth=${localStorage.jwt}`,
    createSurvey: id => axios.post('/api/cint/create-survey', {id}).then(res => res.data),
  },
  language: {
    get: () => axios.get('/api/language').then(res => res.data),
    post: ({locale}) => axios.post('/api/languages', {locale}).then(res => res.data)
  },
  applicationFile: {
    addFile: (data) => axios.post('/api/application-files/add-file' , data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.addresses),
    removeFile: (id) => axios.delete('/api/application-files/remove-file/' + id).then(res => res.data),
    search: (options) => axios.post('/api/application-files/search', options).then(res => res.data),
    patch: (data) => axios.patch('/api/application-files/' + data.id, {data}).then(res => res.data),
  },
  sitemapsFile: {
    addFile: (data) => axios.post('/api/sitemaps-files/add-file' , data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data),
  },
  ankieteoCms: {
    get: (id) => axios.get('/api/ankieteo-cms/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/ankieteo-cms/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/ankieteo-cms/' + id).then(res => res),
    post: (data) => axios.post('/api/ankieteo-cms', {data}).then(res => res.data),
    patch: (data) => axios.patch('/api/ankieteo-cms/' + data.id, {data}).then(res => res.data),
  },
  ankieteoNav: {
    get: (id) => axios.get('/api/ankieteo-nav/' + id).then(res => res.data),
    patch: (id, data) => axios.patch('/api/ankieteo-nav/' + id, {data}).then(res => res.data),
  },
  swresearchCms: {
    get: (id) => axios.get('/api/swresearch-cms/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/swresearch-cms/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/swresearch-cms/' + id).then(res => res),
    post: (data) => axios.post('/api/swresearch-cms', {data}).then(res => res.data),
    patch: (data) => axios.patch('/api/swresearch-cms/' + data.id, {data}).then(res => res.data),
  },
  swresearchNav: {
    get: (id) => axios.get('/api/swresearch-nav/' + id).then(res => res.data),
    patch: (id, data) => axios.patch('/api/swresearch-nav/' + id, {data}).then(res => res.data),
  },
  surveySwitcherChecks: {
    get: (id) => axios.get('/api/survey-switcher-checks/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/survey-switcher-checks/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/survey-switcher-checks/' + id).then(res => res),
    post: (data) => axios.post('/api/survey-switcher-checks', {data}).then(res => res.data),
    patch: (id, data) => axios.patch(`/api/survey-switcher-checks/${id}`, {data}).then(res => res.data),
    check: (id) => axios.get(`/api/survey-switcher-checks/${id}/check`).then(res => res.data),

    history: {
      search: (options, sort) => axios.post('/api/survey-switcher-checks/history/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
      delete: (id) => axios.delete('/api/survey-switcher-checks/history/' + id).then(res => res),
    },
  },
  surveysCategories: {
    list: () => axios.get('/api/surveys-categories/list').then(res => res.data),
    surveys: (filters) => axios.post('/api/surveys-categories/surveys', {filters}).then(res => res.data),
    delete: (id) => axios.delete('/api/surveys-categories/' + id).then(res => res),
    post: (data) => axios.post('/api/surveys-categories', {data}).then(res => res.data),
    patch: (data) => axios.patch(`/api/surveys-categories/${data.id}`, {data}).then(res => res.data),
    surveyPatch: (data) => axios.patch(`/api/surveys-categories/survey/${data.surveyId}`, data).then(res => res.data),
  },
  dictionaries: {
    get: (id) => axios.get('/api/dictionaries/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/dictionaries/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/dictionaries/' + id).then(res => res),
    post: (data) => axios.post('/api/dictionaries', {data}).then(res => res.data),
  },
  dictionaryWords: {
    get: (id) => axios.get('/api/dictionary/words/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/dictionary/words/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/dictionary/words/' + id).then(res => res),
    importFile: formData => axios.post('/api/dictionary/words/import-file', formData).then(res => res.data),
    createFromText: data => axios.post('/api/dictionary/words/text', {data}).then(res => res.data),
  },
  dictionaryWordSimilars: {
    get: (id) => axios.get('/api/dictionary/word/similars/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/dictionary/word/similars/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/dictionary/word/similars/' + id).then(res => res),
    post: (data) => axios.post('/api/dictionary/word/similars', {data}).then(res => res.data),
  },
  notifications: {
    search: (options) => axios.post('/api/notifications/search', options).then(res => res.data),
    unreadCount: () => axios.get('/api/notifications/unread-count').then(res => res.data),
  },
  dashboardNotifies: {
    get: (id) => axios.get('/api/dashboard-notifies/' + id).then(res => res.data),
    getForUser: () => axios.get('/api/dashboard-notifies/get-for-user').then(res => res.data),
    disableForUser: (id) => axios.get(`/api/dashboard-notifies/${id}/disable-for-user`).then(res => res.data),
    search: (options, sort) => axios.post('/api/dashboard-notifies/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/dashboard-notifies/' + id).then(res => res),
    post: (data) => axios.post('/api/dashboard-notifies', {data}).then(res => res.data),
    patch: (data) => axios.patch(`/api/dashboard-notifies/${data.id}`, {data}).then(res => res.data),
  },
  userEmailDomainBlacklists: {
    get: (id) => axios.get('/api/user-email-domain-blacklists/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/user-email-domain-blacklists/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/user-email-domain-blacklists/' + id).then(res => res),
    post: (data) => axios.post('/api/user-email-domain-blacklists', {data}).then(res => res.data),
  },
  surveyChangeHistory: {
    search: (options, sort) => axios.post('/api/survey-change-history/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
  },
  surveyResponseFakers: {
    get: (id) => axios.get('/api/survey-response-fakers/' + id).then(res => res.data),
    search: (options, sort) => axios.post('/api/survey-response-fakers/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    delete: (id) => axios.delete('/api/survey-response-fakers/' + id).then(res => res),
    post: (data) => axios.post('/api/survey-response-fakers', {data}).then(res => res.data),
    patch: (data) => axios.patch('/api/survey-response-fakers', {data}).then(res => res.data),
  },
  city: {
    post: {
      search: data => axios.post('/api/cities/search', data).then(res => res.data),
    },
    districts: {
      post: {
        search: data => axios.post('/api/cities/districts/search', data).then(res => res.data),
      },
    },
  },
  smsApiTokenValidation: token => axios.post('/api/sms-api/validation', {token}).then(res => res.data),
  slackValidation: slackHook => axios.post('/api/slack/validation', {slackHook}).then(res => res.data),
  admin: {
    survey: {
      checkCopyName: (id) => axios.get(`/api/admin/surveys/${id}/check-copy-name`).then(res => res.data),
      swPanelAcceptBulk: (ids) => axios.post('/api/admin/surveys/sw-panel-accept-bulk', {ids}).then(res => res.data),
      getSurveyResponsesCountRepeatedIp: (id) => axios.get('/api/admin/surveys/'+id+'/survey-responses-count-repeated-ip').then(res => res.data),
      patch: {
        swPanel: (surveyId, data) => axios.patch('/api/admin/surveys/' + surveyId + '/sw-panel', {data}).then(res => res.data),
      },
    },
    surveyCollector:{
      get: {
        reportedAccept: ({q}) => axios.get(`/api/admin/survey-collector/reported-accept?q=${q}`).then(res => res.data),
        waitingAccept: ({q}) => axios.get(`/api/admin/survey-collector/waiting-accept?q=${q}`).then(res => res.data),
      },
    },
    user: {
      post: {
        point: (data) => axios.post('/api/admin/users/points', { data }).then(res => res.data),
        deleteUser: (users) => axios.post('/api/admin/users/delete-user', { users } ).then(res => res.data),
      }
    },
    surveyResponse: {
      post: {
        search: data => axios.post('/api/admin/survey-responses/search', data).then(res => res.data),
      }
    },
    userPrize:{
      payoutPayPal: (userPrizeId) => axios.post(`/api/admin/user-prizes/${userPrizeId}/payout-paypal`).then(res => res.data),
      changeStatusFrom5To20ForValidatedUsers: () => axios.post('/api/admin/user-prizes/change-status-from-5-to-20-for-validated-users').then(res => res.data),
      get: {
        deliveredForPayment: (type) => '/api/admin/user-prizes/delivered-for-payment/' + type + '?auth=' + localStorage.jwt,
      },
    },
    stats: {
      prizes: filters => axios.post('/api/admin/stats/prizes', {filters}).then(res => res.data),
      surveyResponses: (filters, cancelToken) => axios.post('/api/admin/stats/survey-responses', {filters}, {cancelToken: cancelToken.token}).then(res => res.data),
      surveys: (filters) => axios.post('/api/admin/stats/surveys', {filters}).then(res => res.data),
      surveyPoints: (filters) => axios.post('/api/admin/stats/survey-points', {filters}).then(res => res.data),
    },
    shippingFind: data => axios.post(`/api/admin/shipping`, data).then(res => res.data),
  },
  user: {
    getActivePanelUsers: () => axios.get('/api/users/active-panel-users'),
    getCollectorsRespondents: data => axios.post(`/api/user/searchRespondentsCollectors`, data).then(res => res.data),
    rewriteSurveyShares: (userFrom, userTo) => axios.post('/api/user-rewrite-survey-shares', { userFrom, userTo }).then(res => res.data),
    auth: {
      login: (credentials, captcha) => axios.post('/api/sign/login', {
        credentials: {
          username: credentials.username,
          password: credentials.password,
          sms: credentials.sms
        },
        "g-recaptcha-response": captcha
      } ).then(res => res.data),
      register: (data) => axios.post('/api/sign/register', {data}).then(res => res),
      registerQuick: (data) => axios.post('/api/sign/register-quick', {data}).then(res => res),
      logout: () => axios.get('/api/sign/logout').then(res => res.data),
    },
    apiKeys: {
      get: () => axios.get('/api/apikeys', {}).then(res => res.data),
      post: () => axios.post('/api/apikeys', {}).then(res => res.data)
    },
    get: {
      user: () => axios.get('/api/user').then(res => res.data.user),
      respondents: () => axios.get('/api/users/respondents').then(res => res.data.users),
      surveys: () => axios.get('/api/user/surveys').then(res => res.data.surveys),
      widgets: () => axios.get('/api/user/widgets').then(res => res.data.widgets),
      groups: () => axios.get('/api/user/groups').then(res => res.data),
      analyses: () => axios.get('/api/user/analyses').then(res => res.data.analyses),
      reports: () => axios.get('/api/user/reports').then(res => res.data.reports),
      reportsCount: () => axios.get('/api/user/reportsCount').then(res => res.data.reportsCount),
      cities: (query) => axios.get('/api/user/cities?query=' + query).then(res => res.data),
      checkUser: (id) => axios.get('/api/user/' + id + '/check-user').then(res => res.data),
      transactions: (page, pageSize) => axios.get('/api/user/transactions?page=' + page + '&pageSize=' + pageSize).then(res => res.data),
      respondentsStats: (data, cancelToken) => axios.post('/api/user/respondentsStats', data, {cancelToken: cancelToken.token}).then(res => res.data),
      companiesStats: (year, month) => {
        let url = '/api/user/companiesStats';
          url += year ? '?year=' + year : '';
          url += month ? '?month=' + month : '';

        return axios.get(url).then(res => res.data)
      },
      changeEmailActivation: activationCode => axios.get('/api/user/change-email/' + activationCode).then(res => res.data),
      changeEmailCancel: () => axios.get('/api/user/change-email-cancel').then(res => res.data),
      changeEmailCancelLimited: activationCode => axios.get('/api/user/change-email/cancel-limited/' + activationCode).then(res => res.data),
      collectorPanelUsersExcluded: () => axios.get('/api/user/collector-panel-users-excluded').then(res => res.data),
    },
    patch: {
      userPlan: (data) => axios.patch('/api/user/user-plan', { data }).then(res => res.data),
      respondentBaseColumn: ({data}) => axios.patch('/api/respondent-base/columns/' + data.id, { data }).then(res => res.data.column),
      userProfile: ({data}) => axios.patch('/api/user/' + data.id, {data}).then(res => res.data.user),
      userEmail: (data) => axios.patch('/api/user/user-email', {data: data}).then(res => res.data),
      userPassword: (data) => axios.patch('/api/user/user-password', {data: data}),
      password: (userId) => axios.patch('/api/user/' + userId + '/password').then(res => res.data.password),
      toggleFavoriteSurvey: (surveyId) => axios.patch('/api/user/toggleFavoriteSurvey/' + surveyId),
      respondentAdmin: ({data}) => axios.patch('/api/user/admin/respondent/' + data.id, {data}).then(res => res.data.user),
      companyAdmin: ({data}) => axios.patch('/api/user/admin/company/' + data.id, {data}).then(res => res.data.user),
      admin: (id, data) => axios.patch('/api/user/admin/admin/' + id, {data}).then(res => res.data),
      favoriteQuestions: ({data}) => axios.patch('/api/user/favoriteQuestions', {data}).then(res => res.data.favoriteQuestionTypes),
    },
    post: {
      survey: (survey) => axios.post('/api/users/surveys', {survey}).then(res => res.data.survey),
      widget: (widget) => axios.post('/api/users/widgets', {widget}).then(res => res.data.widget),
      respondentBase: (respondentBase) => axios.post('/api/user/respondent-base' , respondentBase).then(res => res.data.respondentBase),
      respondentBaseColumn: (data) => axios.post('/api/respondent-base/columns' , data).then(res => res.data.column),
      group: (group) => axios.post('/api/users/groups', group).then(res => res.data.group),
      template: (id) => axios.post('/api/user/question-template/' + id).then(res => res.data.question),
      client: (data) => axios.post('/api/users/clients', data).then(res => res.data.client),
      smtp: (data) => axios.post('/api/users/smtps', data).then(res => res.data.smtp),
      searchSurveys: (data) => axios.post('/api/user/searchSurveys', data).then(res => res.data),
      searchSurveysAll: (data) => axios.post('/api/user/search-surveys-all', data).then(res => res.data),
      userUpdate: (data) => axios.post('/api/user', data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.user),
      userDelete: () => axios.delete('/api/user').then(res => res.data),
      userDeleteCancel: () => axios.post('/api/user/delete-cancel').then(res => res.data),
      forgotPassword: (data) => axios.post('/api/user/forgot-password', data),
      passwordResetKey: (resetKey) => axios.post('/api/user/password-reset-key/' + resetKey),
      passwordReset: (data, resetKey) => axios.post('/api/user/password-reset/' + resetKey, data),
      searchUserPrizes: (data) => axios.post('/api/user/searchUserPrizes', data).then(res => res.data),
      searchRespondents: (data) => axios.post('/api/user/searchRespondents', data).then(res => res.data),
      searchPoints: (data) => axios.post('/api/user/searchPoints', data).then(res => res.data),
      resetPassword: (userId) => axios.post('/api/user/' + userId + '/resetPassword'),
      searchSurveysAdmin: (data) => axios.post('/api/user/searchSurveysAdmin', data).then(res => res.data),
      passwordConfirmation: (password) => axios.post('/api/user/password/confirmation', {password}),
      respondent: (user) => axios.post('/api/user/respondent', {user}).then(res => res.data.user),
      company: (user) => axios.post('/api/user/company', {user}).then(res => res.data.user),
      admin: (data) => axios.post('/api/user/admin', data).then(res => res.data.user),
      searchCompanies: (data) => axios.post('/api/users/searchCompanies', data).then(res => res.data),
      getCsvCompanies: filters => {
        let url = '/api/users/companies.csv';
        let params = [];
        map(filters, (value, key) => {
          if(value){
            params.push([key, value].join('='));
          }
        });

        return url + '?auth=' + localStorage.jwt + '&' + params.join('&');
      },
      searchWebApplicationLogin: (data) => axios.post('/api/webApplicationLogins/search', data).then(res => res.data),
      searchAdmins: (data) => axios.post('/api/users/search-admins', data).then(res => res.data),
      buyPrizeAccount: (prizeId) => axios.post('/api/user/buy-prize-account/' + prizeId, {}).then(res => res.data),
      surveyCollectorPanelTemplate: (data, collectors) => axios.post('/api/user/survey-collector-panel-template', {data, collectors}).then(res => res.data),
      collectorPanelUsersExcluded: (data) => axios.post('/api/user/collector-panel-users-excluded', data).then(res => res.data),
    },
    delete: {
      respondentBaseColumn: (columnId) => axios.delete('/api/respondent-base/columns/' + columnId),
    },
    findQuestions: data => axios.post('/api/user/find-questions', data).then(res => res.data),
    surveysQuestions: {
      search: data => axios.post('/api/user/surveys-questions', data).then(res => res.data),
    },
    questionTemplates: {
      search: (data) => axios.post('/api/user/question-templates/search', data).then(res => res.data),
      delete: (id) => axios.delete('/api/user/question-templates/' + id).then(res => res.data),
      patch: (id, data) => axios.patch('/api/user/question-templates/' + id, {data}).then(res => res.data),
      post: (question, data) => axios.post('/api/user/question-templates', {question, data}).then(res => res.data),
    },
    blockTemplates: {
      search: (data) => axios.post('/api/user/block-templates/search', data).then(res => res.data),
      delete: (id) => axios.delete('/api/user/block-templates/' + id).then(res => res.data),
      patch: (id, data) => axios.patch('/api/user/block-templates/' + id, {data}).then(res => res.data),
      post: (block, data) => axios.post('/api/user/block-templates', {block, data}).then(res => res.data),
    },
    group: {
      users: () => axios.get('/api/user/group/users').then(res => res.data),
    },
  },
  survey: {
    getSurveyEmailStats: (data) => axios.post(`/api/surveys/survey-email-stats`, data),
    getCollectorsPanelEmailStats: (id) => axios.get(`/api/surveys/${id}/collectors-panel-email-stats`),
    templates: ({query}) => axios.get(`/api/surveys/templates?query=${query}&page=1&limit=500`),
    pauseCintSurveys: () => axios.post('/api/surveys/pause-cint-surveys'),
    clearContentInQuestions: (data) => axios.post('/api/surveys/clear-content-in-questions', data).then(res => res.data),
    collectorPanelSendVerify: (surveyId) => axios.post('/api/surveys/' + surveyId + '/collector-panel-send-verify').then(res => res.data),
    checkReplaces: (surveyId, data) => axios.post('/api/surveys/' + surveyId + '/check-replaces', data).then(res => res.data),
    replaceTexts: (surveyId, data) => axios.post('/api/surveys/' + surveyId + '/replace-texts', data).then(res => res.data),
    getResponsesCount: (surveyId) => axios.get('/api/surveys/' + surveyId + '/responses-count').then(res => res.data),
    getEndingCodesCount: (surveyId) => axios.get('/api/surveys/' + surveyId + '/ending-codes-count').then(res => res.data),
    importEndingCodes: (surveyId, data) => axios.post('/api/surveys/' + surveyId + '/ending-codes-import', data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data),
    removeEndingCodes: (surveyId) => axios.get('/api/surveys/' + surveyId + '/ending-codes-remove').then(res => res.data),
    get: {
      webhooks: ({id}) => axios.get('/api/surveys/' + id + '/webhooks').then(res => res.data.webhooks),
      analyses: ({id}) => axios.get('/api/surveys/' + id + '/analyses').then(res => res.data.analyses),
      survey: ({id}) => axios.get('/api/surveys/' + id).then(res => res.data.survey),
      surveys: () => axios.get('/api/surveys').then(res => res.data.surveys),
      surveysAll: () => axios.get('/api/surveys-all').then(res => res.data.surveys),
      collectors: ({id}, filters) => axios.get('/api/surveys/' + id + '/collectors?' + _.map(filters, (value, key) => key + '=' + value).join('&')).then(res => res.data.collectors),
      collectorsCsv: ({id}, filters) => '/api/surveys/' + id + '/collectors.csv?' + _.map(_.merge(filters, {auth: localStorage.jwt}), (value, key) => key + '=' + value).join('&'),
      navResources: (id) => axios.get('/api/surveys/' + id + '/resources').then(res => res.data),
      filters: (id) => axios.get('/api/surveys/' + id + '/filters').then(res => res.data),
      surveyShares: (id) => axios.get('/api/surveys/' + id + '/shares').then(res => res.data.surveyShares),
      progress: (id => axios.get('/api/surveys/' + id + '/progress').then(res => res.data.progress)),
      temporaryCollector: ({id}) => axios.get('/api/surveys/' + id + '/temporary/collector').then(res => res.data.collector),
      quotas: ({id}) => axios.get('/api/surveys/' + id + '/quotas').then(res => res.data.quotas),
      redirects: (id) => axios.get('/api/survey/' + id + '/redirects').then(res => res.data.redirects),
      ownStyles: (id) => axios.get('/api/surveys/' + id + '/own-styles').then(res => res.data),
      loi: (id) => axios.get('/api/surveys/' + id + '/loi').then(res => res.data),
      globalTemplates: () => axios.get('/api/survey/globalTemplates').then(res => res.data.templates),
      questionsList: (id) => axios.get('/api/survey/' + id + '/questionsList').then(res => res.data.questions),
      validationStages: (id, stage = 1) => axios.get(`/api/surveys/${id}/validation-stages?stage=${stage}`),
      validationScoring: (id) => axios.get(`/api/surveys/${id}/validation-scoring`),
    },
    delete: {
      webhooks: ({id, webhookId}) => axios.delete('/api/surveys/' + id + '/webhooks/' + webhookId),
      survey: ({id}) => axios.delete('/api/surveys/' + id).then(res => res.data),
      responses: ({id}) => axios.delete('/api/surveys/' + id + '/responses'),
      redirect: (id) => axios.delete('/api/survey/redirect/' + id),
      template: (templateId) => axios.delete('/api/survey/template/' + templateId),
      endingScreens: (id, screenId) => axios.delete('/api/surveys/' + id + '/endingScreens/' + screenId),
    },
    post: {
      webhooks: (surveyId, data) => axios.post('/api/surveys/' + surveyId + '/webhooks', data).then(res => res.data.webhook),
      analysis: ({survey}, data = {}, temporary = 0) => {
        if(temporary){
          data.temporary = 1;
        }

        return axios.post('/api/surveys/' + survey + '/analyses', {data}).then(res => res.data.analysis)
      },
      surveyShareAdmin:({survey, user}) => axios.post('/api/surveys/' + survey + '/shares-admin', {user: user}).then(res => res.data.surveyShare),
      collectorPanelTemplate: (surveyId, data) => axios.post('/api/surveys/' + surveyId + '/survey-collector-panel-from-template', data).then(res => res.data.analysis),
      set: ({survey, set}) => axios.post('/api/surveys/' + survey + '/sets', { set }).then(res => res.data.set),
      collector: ({survey, collector}) => axios.post('/api/surveys/' + survey + '/collectors', {collector}).then(res => res.data.collector),
      collectorsBulk: ({survey, collectors}) => axios.post('/api/surveys/' + survey + '/survey-collectors/bulk', {collectors}).then(res => res.data),
      copy: (data) => axios.post('/api/surveys/' + data.survey + '/copy', data).then(res => res.data.survey),
      surveyShare:({survey, surveyShare}) => axios.post('/api/surveys/' + survey + '/shares', surveyShare).then(res => res.data.surveyShare),
      quota:(survey, data) => axios.post('/api/surveys/' + survey + '/quotas', {data}).then(res => res.data.quota),
      redirect:(survey, data) => axios.post('/api/survey/' + survey + '/redirect', {data}).then(res => res.data.redirect),
      csvRedirects:(surveyId, data) => axios.post('/api/survey/' + surveyId + '/redirects/file', data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.redirects),
      template: (data, surveyId) => axios.post('/api/survey/' + surveyId + '/template', {data}).then(res => res.data.template),
      globalTemplate: (data) => axios.post('/api/survey/globalTemplate', data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.template),
      searchGlobalTemplates: (data) => axios.post('/api/survey/searchGlobalTemplates', data).then(res => res.data),
      summary: (id, filters) => axios.post('/api/surveys/' + id + '/summaries', {filters}).then(res => res.data),
      endingScreens: (id, data) => axios.post('/api/surveys/' + id + '/endingScreens', {data: data}).then(res => res.data),
      summaryQuestionsResponses: (id, filters) => axios.post('/api/surveys/' + id + '/summaries-questions-responses', {filters}).then(res => res.data),
      filterGroupCreate: (id, data) => axios.post('/api/surveys/' + id + '/filters-group-create', {data}).then(res => res.data),
      filterSerialGroupCreate: (id, data) => axios.post('/api/surveys/' + id + '/filters-serial-group-create', {data}).then(res => res.data),
    },
    patch: {
      webhooks: (surveyId, webhookId, data) => axios.patch('/api/surveys/' + surveyId + '/webhooks/' + webhookId, data).then(res => res.data.webhook),
      survey: ({id, survey}) => axios.patch('/api/surveys/' + id, { survey }).then(res => res.data.survey),
      report: (data) => axios.patch('/api/survey/reports/' + data.id, data).then(res => res.data.report),
      redirect: (data) => axios.patch('/api/survey/redirect/' + data.id, {data}).then(res => res.data.redirect),
      ownStyles: (surveyId, data) => axios.patch('/api/surveys/' + surveyId + '/own-styles', {data}).then(res => res.data),
      endingScreens: (surveyId, screenId, data) => axios.patch('/api/surveys/' + surveyId + '/endingScreens/' + screenId, {data: data}).then(res => res.data),
      unsetOwnStyles: (surveyId) => axios.patch('/api/surveys/' + surveyId + '/unset-own-styles').then(res => res.data),
      filterStatuses: (surveyId, data) => axios.patch('/api/survey/' + surveyId + '/filter-statuses', {data}).then(res => res.data),
      swPanel: {
        rereport: (surveyId, data) => axios.patch('/api/surveys/' + surveyId + '/sw-panel/rereport', data).then(res => res.data),
      },
    },
    surveyResponses: {
      post: {
        search: (sId, data) => axios.post('/api/surveys/' + sId + '/survey-responses/search', data).then(res => res.data),
      }
    },
    reports: {
      search: (data) => axios.post('/api/surveys/reports/search', data).then(res => res.data),
      patchBulk: (ids, data) => axios.patch('/api/survey/report/bulk', {ids, data}).then(res => res.data),
      sendEmails: (ids) => axios.post('/api/surveys/reports/send-emails', {ids}).then(res => res.data),
    }
  },
  surveyResponse: {
    get: {
      surveyResponses:() => axios.get('/api/survey-responses').then(res => res.data.responses),
      statusCodes: () => axios.get('/api/survey-response/statuses').then(res => res.data.statuses),
      countStatusesByIds: (surveyId, ids) => axios.post('/api/survey-responses/' + surveyId + '/get-statuses-grouped', {ids}).then(res => res.data),
    },
    delete: {
      surveyResponse:({id}) => axios.delete('/api/survey-responses/' + id).then(res => res.data),
      surveyResponsesBulk:(surveyId, ids) => axios.post('/api/survey-responses/delete-bulk', {surveyId, ids}).then(res => res.data),
      temporaryResponses: () => axios.delete('/api/survey-response/temporary')
    },
    patch: {
      response: (data) => axios.patch('/api/survey-responses/' + data.id, data).then(res => res.data.response),
      statuses: (data) => axios.patch('/api/survey-response/statuses', {data}).then(res => res.data.userPrizes)
    },
    put: {
      status: (id, status) => axios.put('/api/survey-responses/' + id + '/status', {status}).then(res => res.data),
    },
    post: {
      searchResponses:(data) => axios.post('/api/survey-responses/searchResponses', data).then(res => res.data),
      changeStatusBulk:(ids, status) => axios.post('/api/survey-responses/change-status-bulk', {ids, status}).then(res => res.data),
      surveyResponse: ({guid, surveyResponseId}) => {
        let data = {
          guid,
        };
        if (surveyResponseId) {
          data = {...data, surveyResponseId};
        }

        return axios.post('/api/survey-response', data).then(res => res.data);
      }
    },
    dataProcessing: {
      process: (surveyId, data) => axios.post('/api/survey-responses/data-processing/process', {surveyId, data}).then(res => res.data),
      exclude: (surveyId, ids, value) => axios.post('/api/survey-responses/data-processing/'+surveyId+'/exclude', {ids, value}).then(res => res.data),
      search: (options, sort) => axios.post('/api/survey-responses/data-processing/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    },
    questionResponse: {
      removeFile: (qrId) => axios.patch('/api/survey-responses/question-responses/' + qrId + '/remove-file-path').then(res => res.data),
      uploadFile: (qrId, data) => axios.post('/api/survey-responses/question-responses/' + qrId + '/upload-file', data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.path),
    }
  },
  setBlocks: {
    delete: ({id}) => axios.delete('/api/sets/' + id).then(res => res.data),
    patch: ({id, set}) => axios.patch('/api/sets/' + id, { data: {...set} }).then(res => res.data.set),
    patchAutoPaginate: ({id}) => axios.patch('/api/sets/autoPaginate/' + id).then(res => res.data.survey),
    removePageBreaks: ({id}) => axios.patch('/api/sets/removePageBreaks/' + id).then(res => res.data.survey),
    post: {
      block: ({set, block}) => axios.post('/api/sets/' + set + '/blocks', { block}).then(res => res.data.block),
      copy: ({set, reference, position, copyOptions}) => axios.post('/api/sets/' + set + '/copy', { reference, position, copyOptions }).then(res => res.data.survey),
    },
  },
  blockPages: {
    delete: ({id}) => axios.delete('/api/blocks/' + id).then(res => res.data),
    patch: ({id, block}) => axios.patch('/api/blocks/' + id, { data: {...block} }).then(res => res.data.block),
    patchAutoPaginate: ({id}) => axios.patch('/api/blocks/autoPaginate/' + id).then(res => res.data.survey),
    removePageBreaks: ({id}) => axios.patch('/api/blocks/removePageBreaks/' + id).then(res => res.data.survey),
    post: {
      page: ({block, page}) => axios.post('/api/blocks/' + block + '/pages', { page }).then(res => res.data.page),
      copy: ({block, reference, position, copyOptions}) => axios.post('/api/blocks/' + block + '/copy', { reference, position, copyOptions }).then(res => res.data.survey),
      buttons: ({id, button}) => axios.post('/api/blocks/' + id + '/pages/button', { button }).then(res => res.data.block),
      fromTemplates: ({templates, set, position}) => axios.post('/api/set/' + set + '/from-block-templates', {templates, position}).then(res => res.data.survey),
    },
  },
  tag: {
    get: {
      tags: () => axios.get('/api/tags').then(res => res.data.tags),
      userTags: () => axios.get('/api/tags/users').then(res => res.data.tags),
    },
  },
  page: {
    delete: ({id}) => axios.delete('/api/pages/' + id).then(res => res.data),
    patch: ({id, page}) => axios.patch('/api/pages/' + id, { data: {...page} }).then(res => res.data.page),
    patchAutoPaginate: ({id}) => axios.patch('/api/pages/autoPaginate/' + id).then(res => res.data.survey),
    post: {
      break: ({id, page}) => axios.post('/api/pages/' + id + '/breaks', { page }).then(res => res.data.survey),
      question: ({page, questions}) => axios.post('/api/pages/' + page + '/questions', {questions}).then(res => res.data.questions),
      questions: (data) => axios.post('/api/pages/questions/lists', {data}).then(res => res.data),
      copy: ({page, reference, position, copyOptions}) => axios.post('/api/pages/' + page + '/copy', { reference, position, copyOptions }).then(res => res.data.survey),
    },
  },
  surveyCollector: {
    search: (options, sort) => axios.post('/api/survey-collectors/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
    sendTestEmail: (id, addresses) => axios.post('/api/survey-collectors/' + id + '/send-test', { addresses }).then(res => res.data),
    sendTestPhone: (id, phones) => axios.post('/api/survey-collectors/' + id + '/send-test-sms', { phones }).then(res => res.data),
    patch: ({id, surveyCollector}) => axios.patch('/api/survey-collectors/' + id, { data: {...surveyCollector} }).then(res => res.data.collector),
    bulk: (ids, data) => axios.patch('/api/survey-collector/bulk', {ids, data,}),
    get: {
      collector: ({id}) => axios.get('/api/survey-collectors/' + id).then(res => res.data.collector),
      addresses: ({id}) => axios.get('/api/survey-collectors/emails/' + id + '/addresses').then(res => res.data.addresses),
      waitingAccept: () => axios.get('/api/survey-collector/waiting-accept').then(res => res.data),
      detail: (id) => axios.get('/api/survey-collectors/' + id + '/detail').then(res => res.data),
      tokens: ({id, page, pageSize}) => axios.get('/api/survey-collectors/tokens/' + id + '/tokens?page=' + page + '&pageSize=' + pageSize).then(res => res.data),
    },
    delete: {
      collector: ({id}) => axios.delete('/api/survey-collectors/' + id).then(res => res.data),
      collectors: (data) => axios.post('/api/survey-collector/delete-bulk', data).then(res => res.data),
      responses: ({id}) => axios.delete('/api/survey-collectors/' + id + '/responses')
    },
    post: {
      copy: (collector) => axios.post('/api/survey-collectors/' + collector.id + '/copy').then(res => res.data.collector),
    },
    panel: {
      realizationAutomateSendEmail: (id) => axios.get(`/api/survey-collectors/${id}/realization-automate-send-email`).then(res => res.data),
      get: {
        collectors: () => axios.get('/api/survey-collector/panels').then(res => res.data.collectors),
        stats: ({id}) => axios.get('/api/survey-collectors/panel/' + id + '/stats').then(res => res.data),
        sendEmail: {
          sent: {
            search: (id, data) => axios.post('/api/survey-collectors/panel/' + id + '/sent/search', data).then(res => res.data),
          },
          respondents: {
            search: (id, data) => axios.post('/api/survey-collectors/panel/' + id + '/respondents/search', data).then(res => res.data),
            searchCsv: (id) => '/api/survey-collectors/panel/' + id + '/respondents/search.csv?auth=' + localStorage.jwt,
          }
        },
        posibilityRange: (data, cancelToken) => axios.post('/api/survey-collectors/panel/posibility-range', {data}, {cancelToken: cancelToken.token}).then(res => res.data),
        collectorPosibilityRange: (id, cancelToken) => axios.get('/api/survey-collectors/panel/' + id + '/posibility-range', {cancelToken: cancelToken.token}).then(res => res.data),
        nameAutogenerated: (id, data) => axios.post('/api/survey-collectors/panels/' + id + '/get-name-autogenerated', {data}).then(res => res.data),
        validity: ({id}) => axios.get('/api/survey-collectors/emails/' + id + '/validity').then(res => res.data),

      },
      post: {
        searchCollectors: data => axios.post('/api/survey-collectors/panels/searchPanels', data).then(res => res.data),
        sendEmail: {
          create: ({id}) => axios.post('/api/survey-collectors/panel/' + id + '/send-email', {}).then(res => res.data.collector),
          post: {
            respondents: (collectorId, respondents) => axios.post('/api/survey-collectors/panel/' + collectorId + '/send-email/respondents', {respondents}).then(res => res.data),
          }
        },
        mobilePush: {
          create: ({id}) => axios.post('/api/survey-collectors/panel/' + id + '/mobile-push-settings', {}).then(res => res.data.collector),
          posibilityRange: ({id}) => axios.get('/api/survey-collectors/panel/' + id + '/mobile-push-settings/posibility-range').then(res => res.data),
          send: (collector) => axios.post('/api/survey-collectors/panel/' + collector.id + '/mobile-push-settings/create-sending', {}).then(res => res.data),
          get: {
            sendings: (collector) => axios.get('/api/survey-collectors/panel/' + collector.id + '/mobile-push-settings/' + collector.mobilePushSettings[0].id + '/sendings', {}).then(res => res.data)
          }
        }
      },
    },
    email: {
      get: {
        collectors: () => axios.get('/api/survey-collectors/emails').then(res => res.data.collectors),
        sent: ({id}) => axios.get('/api/survey-collectors/emails/' + id + '/sent').then(res => res.data),
        stats: ({id}) => axios.get('/api/survey-collectors/emails/' + id + '/stats').then(res => res.data),
        validity: ({id}) => axios.get('/api/survey-collectors/emails/' + id + '/validity').then(res => res.data),
      },
      delete: {
        address: ({id}) => axios.delete('/api/survey-collectors/emails/' + id + '/address').then(res => res.data),
      },
      post: {
        searchCollectors: data => axios.post('/api/survey-collectors/emails/searchEmails', data).then(res => res.data),
        addresses: {
          respondentBases: ({id, data}) => axios.post('/api/survey-collectors/emails/' + id + '/addresses/by/respondent-bases', {data}).then(res => res.data)
        }
      }
    },
    sms: {
      get: {
        collectors: () => axios.get('/api/survey-collectors/sms').then(res => res.data.collectors),
        sent: ({id}) => axios.get('/api/survey-collectors/sms/' + id + '/sent').then(res => res.data),
        stats: ({id}) => axios.get('/api/survey-collectors/sms/' + id + '/stats').then(res => res.data),
        respondents: (id) => axios.get('/api/survey-collectors/sms/' + id + '/respondents').then(res => res.data),
        validity: ({id}) => axios.get('/api/survey-collectors/sms/' + id + '/validity').then(res => res.data),
      },
      delete: {
        // address: ({id}) => axios.delete('/api/survey-collectors/sms/' + id + '/address').then(res => res.data),
      },
      post: {
        // searchCollectors: data => axios.post('/api/survey-collectors/sms/searchEmails', data).then(res => res.data),
        // respondents: {
        //   respondentBases: ({id, data}) => axios.post('/api/survey-collectors/sms/' + id + '/addresses/by/respondent-bases', {data}).then(res => res.data)
        // }
      },
      respondents: {
        delete: (id) => axios.delete('/api/survey-collectors/sms/respondents/' + id).then(res => res.data),
        search: (id, data) => axios.post('/api/survey-collectors/sms/'+id+'/respondents/search', data).then(res => res.data),
        addFromBase: (id, baseId) => axios.post('/api/survey-collectors/sms/'+id+'/respondents/from-respondent-sms-base', {baseId}).then(res => res.data),
      }
    },
    tokens: {
      post: {
        tokens: ({id, data}) => axios.post('/api/survey-collectors/tokens/' + id + '/tokens', {data}).then(res => res.data.tokens),
        tokensWithFields: (collectorId, data) => axios.post('/api/survey-collectors/tokens/' + collectorId + '/tokens-with-fileds', data, { headers: { 'content-type': 'multipart/form-data' } } ).then(res => res.data),
      },
      delete: {
        token: ({id}) => axios.delete('/api/survey-collectors/tokens/' + id + '/token').then(res => res.data),
      },
      export: (collectorId) => '/api/survey-collectors/tokens/' + collectorId + '/export?auth=' + localStorage.jwt
    }
  },
  question: {
    attributes: {
      get: (id) => axios.get('/api/question-cbc/attributes/' + id).then(res => res.data),
      delete: (id) => axios.delete('/api/question-cbc/attributes/' + id).then(res => res),
      post: (data) => axios.post('/api/question-cbc/attributes', {data}).then(res => res.data),
      patch: (id, data) => axios.patch('/api/question-cbc/attributes/' + id, {data}).then(res => res.data),

      levels: {
        get: (id) => axios.get('/api/question-cbc/attributes/levels/' + id).then(res => res.data),
        delete: (id) => axios.delete('/api/question-cbc/attributes/levels/' + id).then(res => res),
        post: (data) => axios.post('/api/question-cbc/attributes/levels', {data}).then(res => res.data),
        patch: (id, data) => axios.patch('/api/question-cbc/attributes/levels/' + id, {data}).then(res => res.data),
      },
    },
    get: {
      question: ({id}) => axios.get('/api/questions/' + id).then(res => res.data.question),
      templates: () => axios.get('/api/question/templates').then(res => res.data.templates)
    },
    patch: {
      question: ({id, question}) => axios.patch('/api/questions/' + id, { data: question }).then(res => res.data.question),
      questionType: ({id, data}) => axios.patch('/api/questions/' + id + '/type', data).then(res => res.data),
      answersReplace: (id, answers, changeable = false) => axios.patch('/api/questions/' + id + '/answers/replace', { question: { answers }, changeable }).then(res => res.data.question),
      answersAdd: (id, answers) => axios.patch('/api/questions/' + id + '/answers/add', {answers}).then(res => res.data.answers),
      basketsAdd: (id, baskets) => axios.patch('/api/questions/' + id + '/baskets/add', {baskets}).then(res => res.data.baskets),
      answersCopy: (id, data) => axios.patch('/api/questions/' + id + '/answers/copy', data).then(res => res.data.question),
      rowsAdd: (id, rows) => axios.patch('/api/questions/' + id + '/rows/add', {rows}).then(res => res.data.rows),
      columnsAdd: (id, columns) => axios.patch('/api/questions/' + id + '/columns/add', {columns}).then(res => res.data.columns),
      rowsReplace: (id, rows, changeable = false) => axios.patch('/api/questions/' + id + '/rows/replace', { question: { rows }, changeable }).then(res => res.data.question),
      columnsReplace: (id, columns, changeable = false) => axios.patch('/api/questions/' + id + '/columns/replace', { question: { columns }, changeable }).then(res => res.data.question),
      swapRowsAndColumns: (id) => axios.patch('/api/questions/' + id + '/swap').then(res => res.data),
      reverseAnswers: (id) => axios.patch('/api/questions/' + id + '/reverseAnswers').then(res => res.data.answers),
      reverseColumns: (id) => axios.patch('/api/questions/' + id + '/reverseColumns').then(res => res.data.columns),
      reverseRows: (id) => axios.patch('/api/questions/' + id + '/reverseRows').then(res => res.data.rows),
    },
    delete: {
      question: ({id}) => axios.delete('/api/questions/' + id).then(res => res.data),
      template: ({id}) => axios.delete('/api/questions/' + id + '/template')
    },
    post: {
      basket: ({question, basket}) => axios.post('/api/questions/' + question + '/baskets', { basket }).then(res => res.data.basket),
      answer: ({question, answer}) => axios.post('/api/questions/' + question + '/answers', { answer }).then(res => res.data),
      row: ({question, row}) => axios.post('/api/questions/' + question + '/rows', { row }).then(res => res.data.row),
      column: ({question, column}) => axios.post('/api/questions/' + question + '/columns', { column }).then(res => res.data.column),
      copy: ({question, reference, position, copyOptions}) => axios.post('/api/questions/' + question + '/copy', { reference, position, copyOptions }).then(res => res.data.survey),
      excluding: ({question, excluding}) => axios.post('/api/questions/' + question + '/excludings', { excluding }).then(res => res.data.excluding),
      fromTemplate: ({templates, page, position}) => axios.post('/api/pages/' + page + '/from-question-templates', {templates, position}).then(res => res.data.questions),
      scalePoint: ({question, scalePoint}) => axios.post('/api/questions/' + question + '/scalePoint', { scalePoint }).then(res => res.data.scalePoint),
      javascript: ({question, data}) => axios.post('/api/questions/' + question.id + '/javascript', { data }).then(res => res.data.javascript),
      libraryValues: (questionId, data) => axios.post('/api/questions/' + questionId + '/library-values' , data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data),
    },
  },
  basket: {
    patch: ({id, basket}) => axios.patch('/api/baskets/' + id, { data: basket }).then(res => res.data.basket),
    delete: {
      basket: ({id}) => axios.delete('/api/baskets/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/baskets/deletes', {ids}).then(res => res.data),
    },
  },
  answer: {
    patch: ({id, answer}) => axios.patch('/api/answers/' + id, { data: answer }).then(res => res.data.answer),
    delete: {
      answer: ({id}) => axios.delete('/api/answers/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/answers/deletes', {ids}).then(res => res.data),
    },
  },
  row: {
    patch: ({id, row}) => axios.patch('/api/rows/' + id, { data: row }).then(res => res.data.row),
    delete: {
      row: ({id}) => axios.delete('/api/rows/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/rows/deletes', {ids}).then(res => res.data),
    },
  },
  column: {
    patch: ({id, column}) => axios.patch('/api/question-columns/' + id, { data: column }).then(res => res.data.column),
    delete: {
      column: ({id}) => axios.delete('/api/question-columns/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/question-columns/deletes', {ids}).then(res => res.data),
    },
  },
  excluding: {
    patch: ({id, questionExcluding}) => axios.patch('/api/question-excludings/' + id, { data: {...questionExcluding} }).then(res => res.data.excluding),
    delete: {
      excluding: ({id}) => axios.delete('/api/question-excludings/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/question-excludings/deletes', {ids}).then(res => res.data),
    },
  },
  scalePoint: {
    patch: ({id, scalePoint}) => axios.patch('/api/scale-points/' + id, { data: {...scalePoint} }).then(res => res.data.scalePoint),
    delete: {
      scalePoint: ({id}) => axios.delete('/api/scale-points/' + id).then(res => res.data),
      list: ({ids}) => axios.post('/api/scale-points/deletes', {ids}).then(res => res.data),
    },
  },
  javascript: {
    patch: ({id, javascript}) => axios.patch('/api/question-javascripts/' + id, { data: {action: javascript.action, code: javascript.code} }).then(res => res.data.javascript),
    delete: {
      javascript: ({id}) => axios.delete('/api/question-javascripts/' + id).then(res => res.data),
    },
  },
  analysis: {
    getAnalysisExportFile: filename => `/api/analysis-exports/file/${filename}?auth=${localStorage.jwt}`,
    export: (id, type, lang, options, deleteAfterExport = false, usersIds = []) => {
      const opt = _.map(options, (value, key) => {
        if(value === true){
          value = 1;
        }
        if(value === false){
          value = 0;
        }

        return '&options[' + key + ']=' + value;
      });

      if(type == 'spss'){
        type = 'spss/new-v2';
      }

      let host = '';
      if(process.env.NODE_ENV === 'production'){
        if(window.location.origin.includes('ankieteo.pl')){
          host = 'https://export.ankieteo.pl'
        }
      }

      return host + '/api/analysis-exports/' + id + '/' + type + '?lang=' + lang + '&auth=' + localStorage.jwt + opt.join('') + (deleteAfterExport ? '&delete-after-export=1' : '') + (usersIds.length > 0 ? '&usersIds=' + usersIds.join(',') : '')
    },
    delete: {
      analysis: (id) => axios.delete('/api/analyses/' + id).then(res => res.data),
      analysisShare: ({id}) => axios.delete('/api/analysis/shares/' + id)
    },
    get: {
      analysis: ({id}) => axios.get('/api/analyses/' + id).then(res => res.data.analysis),
      questionSummaries: ({id}, lang) => axios.get('/api/analysis/' + id + '/question-summaries', { headers: { 'translatableLang': lang || 'pl' } }).then(res => res.data.questionSummaries),
      questionSummariesByAnalysisUid: (uid) => axios.get('/api/analysis-question-summaries/' + uid).then(res => res.data.questionSummaries),
      responsesCount: ({id}) => axios.get('/api/analysis/' + id + '/responsesCount').then(res => res.data.responsesCount),
      sharedQuestionSummaries: (analysisShare) => axios.post('/api/analysis/access/analysis-share', {analysisShare}).then(res => res.data),
    },
    post: {
      filterQuestion: (filterQuestion) => {
        const {analysis, question} = filterQuestion;
        ['id', 'analysis', 'question'].map(key => delete filterQuestion[key]);

        return axios.post('/api/analyses/' + analysis + '/filter-questions', { question, filterQuestion }).then(res => res.data.filterQuestion);
      },
      filterCollector: (filterCollector) => {
        const {analysis, collector} = filterCollector;
        return axios.post('/api/analyses/' + analysis + '/filter-collectors', { collector, filterCollector }).then(res => res.data.filterCollector);
      },
      includeQuestion: (includeQuestion) => {
        const {analysis, question} = includeQuestion;
        return axios.post('/api/analyses/' + analysis + '/include-questions', { question, includeQuestion}).then(res => res.data.includeQuestion);
      },
      analysisShare: (analysisShare) => axios.post('/api/analyses/' + analysisShare.analysis + '/shares', {analysisShare}).then(res => res.data.analysisShare),
      searchResponse: ({id}, lang, page, search) => axios.post('/api/analysis/' + id + '/response/search', {page, search}, { headers: { 'translatableLang': lang || 'pl' } }).then(res => res.data),

    },
    patch: {
      analysis: ({id, analysis}) => axios.patch('/api/analyses/' + id, { data: {...analysis} }).then(res => res.data.analysis),
      filterDate: ({id, filter}) => axios.patch('/api/analyses/filter-date/' + id, { filter }).then(res => res.data.filterDate),
      analysisShare: ({id, data}) => axios.patch('/api/analysis/shares/' + id, { data }).then(res => res.data.analysisShare)
    },

    filterQuestion: {
      delete:{
        filterQuestion: ({id}) => axios.delete('/api/analyses/filter-questions/' + id).then(res => res.data),
      },
      patch: (filterQuestion) => {
        const {id, question} = filterQuestion;
        ['analysis', 'question'].map(key => delete filterQuestion[key]);

        return axios.patch('/api/analyses/filter-questions/' + id, { question, filterQuestion }).then(res => res.data.filterQuestion);
      },
    },
    filterCollector: {
      delete:{
        filterCollector: ({id}) => axios.delete('/api/analyses/filter-collectors/' + id).then(res => res.data),
      }
    },
    includeQuestion: {
      delete:{
        includeQuestion: ({id}) => axios.delete('/api/analyses/include-questions/' + id).then(res => res.data),
      }
    },
    questionSummary: {
      get: (questionSummaryId) => axios.get('/api/analysis/question-summaries/' + questionSummaryId ).then(res => res.data),
      patch: (questionSummary) => axios.patch('/api/analysis/question-summaries/' + questionSummary.id, { questionSummary }).then(res => res.data.questionSummary),
    },
    advanced: {
      get: (id) => axios.get('/api/analysis/advanceds/' + id).then(res => res.data),
      search: (options, sort) => axios.post('/api/analysis/advanceds/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
      delete: (id) => axios.delete('/api/analysis/advanceds/' + id).then(res => res),
      post: (data) => axios.post('/api/analysis/advanceds', {data}).then(res => res.data),
      patch: (id, data) => axios.patch('/api/analysis/advanceds/' + id, {data}).then(res => res.data),
      getData: (id) => axios.get('/api/analysis/advanceds/' + id + '/data').then(res => res.data),
    }
  },
  widget: {
    patch: {
      widgets: (widgets) => axios.patch('/api/widgets', { widgets }).then(res => res.data.user),
      widget: ({id, widget}) => axios.patch('/api/widget/' + id, { widget }).then(res => res.data.widget),
    },
    delete: ({id}) => axios.delete('/api/widgets/' + id).then(res => res.data.widget),
    get: {
      widget: ({id}) => axios.get('/api/widgets/' + id).then(res => res.data.data),
    }
  },
  points: {
    get: () => axios.get('/api/user-points').then(res => res.data.points),
    getTypes: () => axios.get('/api/user-point/types').then(res => res.data.types),
    getWhats: () => axios.get('/api/user-point/whats').then(res => res.data.whats),
  },
  plan: {
    delete: ({id}) => axios.delete('/api/plans/' + id),
    copy: (id) => axios.get('/api/plans/' + id + '/copy'),
    post: {
      plan: (plan) => axios.post('/api/plans', {plan}).then(res => res),
      searchPlans: (data) => axios.post('/api/plans/searchPlans', data).then(res => res.data),
      dataWithDiscount: (id, period, discount) => axios.post('/api/plans/data/' + id + '?period=' + period, {discount}).then(res => res.data),
    },
    patch: ({id, data}) => axios.patch('/api/plans/' + id, {data}).then(res => res.data.plan),
    get: {
      plan: (id) => axios.get('/api/plans/' + id).then(res => res),
      plans: (demo = '', onSale = '') => axios.get('/api/plans?demo=' + demo + '&onSale=' + onSale).then(res => res.data.plans),
      userPlans: () => axios.get('/api/plans/user').then(res => res.data),
      planData: (id, period) => axios.get('/api/plans/data/' + id + '?period=' + period).then(res => res.data),
    },
    patchUserFreePlan: id => axios.patch('/api/plans/' + id + '/free').then(res => res.data.userPlan)
  },
  planCategory: {
    list: () => axios.get('/api/plan-categories/list'),
    patch: ({id, data}) => axios.patch('/api/plan-categories/' + id, {data}).then(res => res.data.plan),
    planCategorySetting: {
      patch: ({id, data}) => axios.patch('/api/plan-category-settings/' + id, {data}),
    }
  },
  filter: {
    post: (filter) => axios.post('/api/filter', filter).then(res => res.data.filter),
    delete: ({id}) => axios.delete('/api/filter/' + id),
    patch: (data) => axios.patch('/api/filter/' + data.filter.id, data).then(res => res.data.filter),
  },
  quotaNew: {
    post: (quotaNew) => axios.post('/api/quota-new', quotaNew).then(res => res.data.quotaNew),
    delete: ({id}) => axios.delete('/api/quota-new/' + id),
    patch: (data) => axios.patch('/api/quota-new/' + data.quotaNew.id, data).then(res => res.data.quotaNew),
    getCsv: surveyId => `/api/quota-new/list.csv?survey=${surveyId}&auth=` + localStorage.jwt,
    remove: ids => axios.post('/api/quota-new/delete', {ids}),
  },
  piping: {
    post: ({piping, surveyId}) => axios.post('/api/surveys/' + surveyId + '/pipings', {piping}).then(res => res.data.piping),
    delete: ({id}) => axios.delete('/api/pipings/' + id),
    patch: ({piping}) => axios.patch('/api/pipings/' + piping.id, {data: {...piping}}).then(res => res.data.piping),
  },
  filterSerial: {
    copy: (filterId) => axios.post(`/api/survey/filter-serial/${filterId}/copy`).then(res => res.data.filter),
    post: ({filterSerial, surveyId}) => axios.post('/api/surveys/' + surveyId + '/filterSerial', {filterSerial}).then(res => res.data.filter),
    patch: ({filterSerial}) => axios.patch('/api/filter-serial/' + filterSerial.id, {filterSerial}).then(res => res.data.filter),
    delete: ({id}) => axios.delete('/api/filter-serial/' + id),
  },
  filterIterative: {
    copy: (filterId) => axios.post(`/api/survey/filter-iterative/${filterId}/copy`).then(res => res.data.filter),
    post: ({filterIterative, surveyId}) => axios.post('/api/surveys/' + surveyId + '/filterIterative', {filterIterative}).then(res => res.data.filter),
    patch: ({filterIterative}) => axios.patch('/api/filter-iterative/' + filterIterative.id, {filterIterative}).then(res => res.data.filter),
    delete: ({id}) => axios.delete('/api/filter-iterative/' + id),
    questionTo: {
      post: ({questionTo, filterIterativeId}) => axios.post('/api/filter-iterative/' + filterIterativeId + '/questionTo', {questionTo}).then(res => res.data.questionTo),
      delete: ({id}) => axios.delete('/api/filter-iterative/question-to/' + id),
      patch: ({data}) => axios.patch('/api/filter-iterative/question-to/' + data.id, {data}).then(res => res.data.questionTo),
    }
  },
  prizeCategory: {
    get: () => axios.get('/api/prize-categories').then(res => res.data.prizeCategories),
    delete: ({id}) => axios.delete('/api/prize-categories/' + id),
    patch: ({id, prizeCategory}) => axios.patch('/api/prize-categories/' + id, prizeCategory).then(res => res.data.prizeCategory),
    post: ({prizeCategory}) => axios.post('/api/prize-categories' , prizeCategory).then(res => res.data.prizeCategory),
  },
  prize: {
    get: () => axios.get('/api/prizes').then(res => res.data.prizes),
    delete: ({id}) => axios.delete('/api/prizes/' + id),
    patch: ({id, prize}) => axios.post('/api/prizes/' + id + '?_method=PATCH', prize).then(res => res.data.prize),
    post: {
      prize: ({prize}) => axios.post('/api/prizes' , prize).then(res => res.data.prize),
      searchPrizes: data => axios.post('/api/prizes/searchPrizes', data).then(res => res.data)
    }
  },
  respondentBase: {
    get: (data) => axios.get('/api/respondent-bases?page=' + data.page + '&pageSize=' + data.pageSize).then(res => res.data),
    delete: ({id}) => axios.delete('/api/respondent-bases/' + id),
    patch: (data) => axios.patch('/api/respondent-bases/' + data.data.id, data).then(res => res.data.respondentBase),
    address: {
      get: (respondentBaseId) => axios.get('/api/respondent-bases/' + respondentBaseId + '/addresses').then(res => res.data.addresses),
      post: {
        post: (respondentBaseId, data) => axios.post('/api/respondent-bases/' + respondentBaseId + '/addresses' , { data }).then(res => res.data.address),
        text: (data) => axios.post('/api/respondent-bases/' + data.respondentBaseId + '/addresses/text' , data).then(res => res.data.addresses),
        file: (respondentBaseId, data) => axios.post('/api/respondent-bases/' + respondentBaseId + '/addresses/file' , data, { headers: { 'content-type': 'multipart/form-data' } }).then(res => res.data.addresses),
      },
      delete: ({id}) => axios.delete('/api/respondent-base/addresses/' + id),
      deleteBulk: (baseId, ids) => axios.post('/api/respondent-base/' + baseId + '/respondents/delete/bulk', {ids}).then(res => res.data),
      patch: (data) => axios.patch('/api/respondent-base/addresses/' + data.id, data).then(res => res.data.address),
    }
  },
  respondentSmsBases: {
    add: (data) => axios.post('/api/respondent-sms-bases' , { data }).then(res => res.data),
    search: data => axios.post('/api/respondent-sms-bases/search', data).then(res => res.data),
    delete: (id) => axios.delete('/api/respondent-sms-bases/' + id).then(res => res.data),
    respondents: {
      add: (baseId, data) => axios.post('/api/respondent-sms-bases/'+baseId+'/respondents' , { data }).then(res => res.data),
      addText: (baseId, respondents) => axios.post('/api/respondent-sms-bases/'+baseId+'/respondents/text' , { respondents }).then(res => res.data),
      edit: (respondentId, data) => axios.patch('/api/respondent-sms-bases/respondents/' + respondentId , { data }).then(res => res.data),
      search: (baseId, data) => axios.post('/api/respondent-sms-bases/'+baseId+'/respondents/search', data).then(res => res.data),
      delete: (id) => axios.delete('/api/respondent-sms-bases/respondents/' + id).then(res => res.data),
      deleteBulk: (baseId, ids) => axios.post('/api/respondent-sms-bases/' + baseId + '/respondents/delete/bulk', {ids}).then(res => res.data),
    }
  },
  group: {
    get: {
      usersCollectorPanelUsersExcluded: () => axios.get('/api/group/users-collector-panel-users-excluded').then(res => res.data),
    },
    user: {
      post: (data) => axios.post('/api/groups/' + data.groupId + '/users' , data).then(res => res.data),
      patch: (data) => axios.patch('/api/groups/users/' + data.groupUserId , data).then(res => res.data.user),
      delete: ({id}) => axios.delete('/api/groups/' + id + '/user'),
      invite: (data) => axios.post('/api/groups/' + data.groupId + '/invite', data).then(res => res.data.user),
      acceptInvitation: (groupId) => axios.post('/api/groups/' + groupId + '/accept').then(res => res.data),
      rejectInvitation: (groupId) => axios.post('/api/groups/' + groupId + '/reject'),
    }
  },
  surveyShared: {
    summary: {
      check: (guid, password) => axios.post(`/api/survey-shared/summaries/${guid}/check` , {password}).then(res => res.data),
      get: (id) => axios.get('/api/survey-shared/summaries/' + id).then(res => res.data),
      search: (options, sort) => axios.post('/api/survey-shared/summaries/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
      delete: (id) => axios.delete('/api/survey-shared/summaries/' + id).then(res => res),
      post: (data) => axios.post('/api/survey-shared/summaries', {data}).then(res => res.data),
    },
    quotas: {
      check: (guid, password) => axios.post(`/api/survey-shared/quotas/${guid}/check` , {password}).then(res => res.data),
      get: (id) => axios.get('/api/survey-shared/quotas/' + id).then(res => res.data),
      search: (options, sort) => axios.post('/api/survey-shared/quotas/search?sort='+sort.sortField+'&direction='+sort.sortOrder, options).then(res => res.data),
      delete: (id) => axios.delete('/api/survey-shared/quotas/' + id).then(res => res),
      post: (data) => axios.post('/api/survey-shared/quotas', {data}).then(res => res.data),
    },
  },
  surveyShare: {
    delete: ({id}) => axios.delete('/api/survey-shares/' + id),
  },
  code: {
    post: {
      code: ({code}) => axios.post('/api/codes' , code).then(res => res.data.code),
      searchCodes: (data) => axios.post('/api/codes/search', data).then(res => res.data)
    },
    delete: ({id}) => axios.delete('/api/codes/' + id),
    patch: (data) => axios.patch('/api/codes/' + data.id, data).then(res => res.data.code),
  },
  news: {
    get: {
      news: () => axios.get('/api/news').then(res => res.data.news)
    },
    delete: ({id}) => axios.delete('/api/news/' + id),
    post: {
      news: (data) => axios.post('/api/news' , data).then(res => res.data.news),
      searchNewses: (data) => axios.post('/api/newses/searchNewses' , data).then(res => res.data),
    },
    patch: (data) => axios.patch('/api/news/' + data.id, data).then(res => res.data.news),
  },
  userPrize: {
    get: {
      userPrizes: () => axios.get('/api/user-prizes').then(res => res.data.userPrizes),
      statusCodes: () => axios.get('/api/user-prize/statuses').then(res => res.data.statuses),
    },
    patch: (data) => axios.patch('/api/user-prizes/' + data.id, data).then(res => res.data.userPrize),
    patchStatuses: (data) => axios.patch('/api/user-prize/statuses', {data}).then(res => res.data.userPrizes),
    resendVerificationMail: (id) => axios.post(`/api/user-prizes/${id}/resend-verification-mail`).then(res => res.data.userPrizes),
  },
  transaction: {
    post: {
      search: data => axios.post('/api/transactions/search', data).then(res => res.data),
    },
    payU: {
      post: {
        order: {
          plan: (plan, data) => axios.post('/api/transaction/plan/payU/' + plan, data).then(res => res.data),
          sub: (plan, data) => axios.post('/api/subscription/plan/payU/' + plan, data).then(res => res.data),
        }
      }
    },
    payPal: {
      post: {
        order: {
          plan: (plan, data) => axios.post('/api/transaction/plan/payPal/' + plan, data).then(res => res.data)
        }
      }
    },
    blueMedia: {
      post: {
        order: {
          plan: (plan, data) => axios.post('/api/transaction/plan/blueMedia/' + plan, data).then(res => res.data),
          sub: (plan, data) => axios.post('/api/subscription/plan/blueMedia/' + plan, data).then(res => res.data),
          cancelSub: () => axios.post('api/subscription/cancel')
        }
      }
    }
  },
  report: {
    get: {
      reports: () => axios.get('/api/reports').then(res => res.data.reports)
    },
    delete: ({id}) => axios.delete('/api/reports/' + id),
    post: {
      report: (data) => axios.post('/api/reports' , data).then(res => res.data.report),
      searchReports: (data) => axios.post('/api/reports/searchReports' , data).then(res => res.data),
    },
    patch: (data) => axios.patch('/api/reports/' + data.id, data).then(res => res.data.report),
    sendToSecretariat: (id) => axios.post(`/api/surveys/reports/${id}/send-to-secretariat`).then(res => res.data.report),
  },
  caseStudy: {
    get: () => axios.get('/api/caseStudies').then(res => res.data.data),
    delete: ({id}) => axios.delete('/api/caseStudies/' + id),
    post: {
      report: (data) => axios.post('/api/caseStudies' , data).then(res => res.data.data),
      searchReports: (data) => axios.post('/api/caseStudies/searchReports' , data).then(res => res.data),
    },
    patch: (data) => axios.patch('/api/caseStudies/' + data.id, data).then(res => res.data.data),
  },
  mediaInfo: {
    get: () => axios.get('/api/mediaInfos').then(res => res.data.data),
    delete: ({id}) => axios.delete('/api/mediaInfos/' + id),
    post: {
      report: (data) => axios.post('/api/mediaInfos' , data).then(res => res.data.data),
      searchReports: (data) => axios.post('/api/mediaInfos/searchReports' , data).then(res => res.data),
    },
    patch: (data) => axios.patch('/api/mediaInfos/' + data.id, data).then(res => res.data.data),
  },
  client: {
    patch: (data) => axios.patch('/api/clients/' + data.id, data).then(res => res.data.client),
    delete: ({id}) => axios.delete('/api/clients/' + id),
  },
  smtp: {
    search: (data) => axios.post('/api/smtps/search' , data).then(res => res.data),
    post: (data) => axios.post('/api/smtps', {data}).then(res => res.data.smtp),
    patch: (data) => axios.patch('/api/smtps/' + data.id, {data}).then(res => res.data.smtp),
    delete: (id) => axios.delete('/api/smtps/' + id),
  },
  quota: {
    delete: ({id}) => axios.delete('/api/quotas/' + id).then(res => res),
    patch: (id, data) => axios.patch('/api/quotas/' + id, {data}).then(res => res.data.quota),
  },
  ban: {
    get: {
      categories: () => axios.get('/api/ban/categories').then(res => res.data.categories)
    },
    post: {
      bulk: (data) => axios.post('/api/ban/bulk', data)
    },
    delete: {
      bulk: (data) => axios.delete('/api/ban/bulk', {data: {data}})
    },
    patch: data => axios.patch('/api/bans/' + data.id, data).then(res => res.data.ban)
  },
  configuration: {
    delete: ({id}) => axios.delete('/api/admin/configurations/' + id),
    post: {
      configuration: (data) => axios.post('/api/admin/configurations' , data).then(res => res.data.configuration),
      searchConfigurations: (data) => axios.post('/api/admin/configurations/searchConfigurations' , data).then(res => res.data),
    },
    patch: (data) => axios.patch('/api/admin/configurations/' + data.id, data).then(res => res.data.configuration),
  },
  panelBook: {
    post: {
      search: (data) => axios.post('/api/panel-book/search' , data).then(res => res.data),
    },
  },
  surveyCollectorPanelTemplate: {
    get: () => axios.get('/api/survey-collector-panel-templates').then(res => res.data),
    checkPrice: (data) => axios.post('/api/survey-collector-panel-templates/check-price', data).then(res => res.data),
    search: (data) => axios.post('/api/survey-collector-panel-templates/search', data).then(res => res.data),
  },
  topSurveysRank: {
    post: {
      search: data => axios.post('/api/top-surveys-ranks/search', data).then(res => res.data),
      accept: data => axios.post('/api/top-surveys-ranks/accept', data).then(res => res.data),
    },
    patch: data => axios.patch('/api/top-surveys-ranks/' + data.id, data).then(res => res.data),
    delete: id => axios.delete('/api/top-surveys-ranks/' + id).then(res => res.data),
  },
  socialRank: {
    post: {
      search: data => axios.post('/api/social-rank/search', data).then(res => res.data),
      accept: data => axios.post('/api/social-rank/accept', data).then(res => res.data),
    },
  },
  account: {
    stats: {
      main: (data) => axios.post('/api/account/stats', data).then(res => res.data),
      responses: (data) => axios.post('/api/account/stats/responses', data).then(res => res.data),
    },
  },
  questionResponse: {
    question: {
      patchDetails: (question, data) => axios.patch('/api/question-response/questions/' + question, data).then(res => res.data),
    }
  },
  reportDownloads: {
    download: (search) => '/api/report-downloads/download?search='+search+'&auth=' + localStorage.jwt,
    post: {
      search: (data) => axios.post('/api/report-downloads/search' , data).then(res => res.data),
    },
  },
  tagsQuestion: {
    get: () => axios.get('/api/tags-question').then(res => res.data),
  },
  youtube: {
    getGallery: () => axios.get('/api/youtube/gallery').then(res => res.data),
  },
}
